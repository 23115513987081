<template>
  <div id="app">
    <v-app>
      <v-main class="grey lighten-4">
        <ErrorMessage/>
        <SuccessMessage/>
        <router-view/>
      </v-main>
    </v-app>
  </div>
</template>
<script>

import ErrorMessage from "@/components/messages/ErrorMessage.vue";
import SuccessMessage from "@/components/messages/SuccessMessage";

export default {
    components: {SuccessMessage, ErrorMessage}
}
</script>

