import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        user: {},
        isLoggedIn: false,
    },
    mutations: {
        user: (state, payload) => {
            state.user = payload
        },
        isLoggedIn: (state, payload) => {
            state.isLoggedIn = payload
        }
    },
    actions: {
        user({commit}, payload) {
            commit("user", payload);
        },
        isLoggedIn({commit}, payload) {
            commit("isLoggedIn", payload);
        }
    },
})
export default store
