import Vue from 'vue'

Vue.directive('number', {
    bind(el) {
        el.addEventListener('keypress', (e) => {
            if (e.charCode < 48 || e.charCode > 57) {
                e.preventDefault()
            }
        })
    }
})
