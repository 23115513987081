export default [
    {
        path: '*',
        name: 'notFound',
        component: () => import('../views/404.vue'),
        meta: {
            title: 'Page non trouvée'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/auth/login.vue'),
        meta: {
            title: 'Connexion'
        }
    },
    {
        path: '/',
        name: 'home',
        component: () => import('../views/home/index.vue'),
        meta: {
            title: 'Accueil'
        }
    },
    {
        path: '/list',
        name: 'list',
        component: () => import('../views/home/list.vue'),
        meta: {
            title: 'Utilisateurs'
        }
    },
]