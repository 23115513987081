import Vue from 'vue'
import App from './App.vue'
import './mixin'
import vuetify from "./plugins/vuetify"
import router from "./routes"
import store from './store/index'
import {func} from './functions'
import './directives'


import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue"
import Overlay from "@/components/Overlay.vue"

Vue.component('ConfirmDialog', ConfirmDialog)
Vue.component('Overlay', Overlay)

Vue.prototype.$func = func
Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    store,
    router,
    vuetify,
}).$mount('#app')
