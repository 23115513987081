import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import CommonRoutes from "@/routes/commonRoutes";

Vue.use(VueRouter)

const index = [
    ...CommonRoutes,
]
const router = new VueRouter({
    mode: 'history',
    routes: index,
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        } else if (to.hash) {
            return {
                selector: to.hash
            };
        } else {
            return {x: 0, y: 0};
        }
    }
})

/***
 * Auth
 */


router.beforeEach((to, from, next) => {
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = store.state.isLoggedIn;
    if (authRequired && !loggedIn) {
        next('/login');
    } else if (!authRequired && loggedIn) {
        next('/');
    } else {
        next()
    }
});

/***
 * Page title
 */
router.beforeEach((to, from, next) => {
    document.title = process.env.VUE_APP_NAME + '::' + to.meta.title
    next()
});

export default router
