import Vue from 'vue'
import Vuetify from 'vuetify'
import fr from 'vuetify/es5/locale/fr';
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

import '../style/main.css'

Vue.use(Vuetify)
const opts = {
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#D40C56',
                secondary: '#FFC415',
            }
        },
    },
    lang: {
        locales: {fr},
        current: 'fr',
    },
    icons: {
        iconfont: 'mdi',
    },
}
export default new Vuetify(opts)
