import store from "@/store";
import * as moment from 'moment';

/**
 *
 * @type {{progressColor: ((function(*): (string|undefined))|*), dateFormat(*): string, export(*, *): void}}
 */
export const func = {

    /**
     *
     * @param url
     * @param params
     */
    export(url, params) {
        let token = store.state.user.token
        let userId = store.state.user.id
        let DownloadURL = process.env.VUE_APP_BASE_URL + '/api/' + url + '?token=' + token + '&id=' + userId + '&params=' + JSON.stringify(params);
        window.open(DownloadURL, '_blanc')
    },

    /**
     *
     * @param date
     * @returns {string}
     */
    dateFormat(date) {
        return moment(date).format('DD/MM/YYYY, HH:mm');
    },

    /**
     *
     * @returns {string}
     */
    randomColor() {
        const colors = ["bg-primary-subtle"]
        const random = Math.floor(Math.random() * colors.length)
        return colors[random]
    },

    /**
     *
     * @param fullName
     * @returns {string}
     */
    avatarName(fullName) {
        return fullName.split(' ').map(name => name[0]).join('').toUpperCase()
    },
}